<template lang="pug">
	.tag(:class="classNames")
		.tag__label
			slot
				| {{label}}
</template>

<script>
export default {
	props: {
		label: String,
		type: {
			default: 'white',
			validator: value => ~[
				'white', 'green',
			].indexOf(value),
		},
		size: {
			default: 'md',
			validator: value => ~['sm', 'md', 'lg'].indexOf(value),
		},
	},
	computed: {
		classNames() {
			const cn = [
				`--size-${this.size}`,
				`--type-${this.type}`,
			]

			return cn
		},
	},
}
</script>
<style lang="scss" scoped>
.tag {
	border-radius: 6px;

	&__label {

	}

	&.--size {
		&-sm {
			padding: 2px 6px;
			font-size: 12px;
		}

		&-md {
			padding: 2px 6px;
			font-size: 14px;
		}

	}
	&.--type {
		&-white {
			color: color(primary);
			background-color: color(white);
		}
		&-green {
			color: color(white);
			background-color: color(green);
		}
	}
}
</style>
