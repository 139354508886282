<template lang="pug">
    ul.pagination(v-if="hasPagination")
        li.page(
            :class="{ disabled: isFirst }"
            @click="prevPage"
        ) prev

        li.page(
            v-for="page in pages"
            :class="page === current ? 'active' : ''"
            @click="changePage(page)"
        ) {{page}}

        li.page(
            :class="{ disabled: isLast }"
            @click="nextPage"
        ) next

</template>

<script>
const SEPARATOR = '...'

export default {
    name: 'Pagination',
    props: {
        total: {
            type: [Number, String],
            default: 0,
        },
        current: {
            type: Number,
            default: 1,
        },
        limit: {
            type: [Number, String],
            default: 20,
        },
        offset: {
            type: [Number, String],
            default: 0,
        },
    },
    data() {
        return {
            pages: [],
        }
    },
    computed: {
        hasPagination() {
            return this.total > this.limit
        },
        allPages() {
            return Math.ceil(this.total / this.limit)
        },
        isFirst() {
            return this.current === 1
        },
        isLast() {
            return this.current === this.allPages
        },
    },
    methods: {
        changePage(page) {
            if (page === SEPARATOR) return
            this.$emit('change-page', page)
        },
        nextPage() {
            this.changePage(this.current + 1)
        },
        prevPage(){
            this.changePage(this.current - 1)
        },
        initPages() {
            if (this.allPages > 1 && this.allPages <= 7) {
                for (let i = 0; i < this.allPages; i++) {
                    this.$set(this.pages, i, i + 1)
                }
                return
            }

            if (this.allPages > 7) {
                this.$set(this.pages, 0, 1)
                this.$set(this.pages, 1, this.current > 4 ? SEPARATOR : this.current - 2)
                this.$set(this.pages, 2, this.current - 1)
                this.$set(this.pages, 3, this.current)
                this.$set(this.pages, 4, this.current + 1)
                this.$set(this.pages, 5, this.allPages - this.current > 2 ? SEPARATOR : this.current + 2)
                this.$set(this.pages, 6, this.allPages)

                if (this.current <= 4) {
                    for (let i=1; i<5; i++) {
                        this.$set(this.pages, i, i + 1)
                    }
                }
                if (this.current >= this.allPages - 3) {
                    for (let i=2, j=4; i<6; i++, j--) {
                        this.$set(this.pages, i, this.allPages - j)
                    }
                }
            }
        },
        updatePages() {
            this.pages.length = 0
            this.initPages()
            this.changePage(1)
        },
    },
    watch: {
        total: {
            handler() {
                this.updatePages()
            },
        },
        limit: {
            handler() {
                this.updatePages()
            },
        },
        current: {
            immediate: true,
            handler(value) {
                if (this.allPages === 1 || value < 1) return
                this.initPages()
            },
        },
    },

}
</script>

<style lang="scss" scoped>
.pagination {
    display: flex;
}
.page {
    margin: 0 3px;
    padding: 0 4px;
    font-size: 13px;
    border: 1px solid #919191;
    border-radius: 3px;
    background-color: #fafafa;
    color: #303030;
    user-select: none;
    cursor: pointer;
    &:hover {
        border-color: #303030;
    }
    &.active {
        font-weight: bold;
        border-color: #303030;
        background-color: #FFFFFF;
    }
    &.disabled {
        pointer-events: none;
        background-color: #d6d6d6;
    }
}
</style>